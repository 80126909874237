import { Controller } from "@hotwired/stimulus";
import Template from "../../lib/personalization/svg/template";

const SVG_ATTRIBUTES = {
  width: "100%",
  height: "100%",
  x: "-50%",
  y: "-50%"
};

export default class extends Controller {
  static values = {
    url: String,
    invert: { type: Boolean, default: false },
    data: { type: Object, default: null },
    fonts: { type: Object, default: null }
  };

  static targets = ["canvas"];

  async connect() {
    this.svg = null;
    await this.fetchAndUpdate();
  }

  async fetchAndUpdate() {
    if (!this.hasUrlValue) {
      return;
    }

    try {
      Template.fetch(this.urlValue)
        .then((svg) => { this.svg = svg; })
        .then(this.update.bind(this));
    } catch (error) {
      console.error("There was an error fetching the template", error); // eslint-disable-line no-console
    }
  }

  async update() {
    const fonts = this.fontsValue;
    const template = await this.svg.render(this.dataValue, fonts, {})
      .then((rendering) => rendering.template);

    if (template) {
      Object.entries(SVG_ATTRIBUTES).forEach(([key, value]) => {
        template.setAttribute(key, value);
      });

      this.canvasTarget.replaceChildren(template);
    }
  }
}
