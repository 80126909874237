import { Controller } from "@hotwired/stimulus";

/* eslint-disable no-undef */
export default class extends Controller {
  static get values() {
    return {
      token: String,
      auth: String,
      env: String,
      url: String,
    };
  }

  connect() {
    if (this.previewFromCache ||
        !this.hasTokenValue ||
        !this.hasAuthValue ||
        !this.hasEnvValue ||
        !this.hasUrlValue) {
      return;
    }

    this._initGtm();
    if (titan.analytics_data !== undefined) {
      this._processData();
    }
  }

  addToCart() {
    this._addToCart();
  }

  get previewFromCache() {
    return document.documentElement.hasAttribute("data-turbo-preview");
  }

  _initGtm() {
    window.dataLayer = window.dataLayer || [];
    const controller = this;
    // Boilerplate GA install snippet
    (function(w, d, s, l, i) {
      w[l]=w[l]||[];
      w[l].push({'gtm.start': new Date().getTime(), event: 'gtm.js'});
      let f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),
        dl=l!=='dataLayer'?'&l='+l:''; j.async=true;
      j.src=`https://${controller.urlValue}/gtm.js?id=`+
            i+dl+`&gtm_auth=${controller.authValue}&gtm_preview=${controller.envValue}&gtm_cookies_win=x`;
      f.parentNode.insertBefore(j,f);
    }(window, document, 'script', 'dataLayer', controller.tokenValue));
  }

  _processData() {
    window.dataLayer.push({
      'visitor_uuid': titan.analytics_data.visitor_uuid,
      'cart_value': titan.analytics_data.cart_value,
      'cart_items': titan.analytics_data.cart_items,
    });

    if (titan.analytics_data.controller_name === "product_detail_pages") {
      this._viewItem();
    } else if (titan.analytics_data.controller_name === "product_list_pages") {
      this._viewCategory();
    } else if (titan.analytics_data.controller_name === "billing_addresses") {
      this._beginCheckout();
    } else if (titan.analytics_data.controller_name === "shipping_addresses") {
      this._addShippingInfo();
    } else if (titan.analytics_data.controller_name === "payments") {
      this._addPaymentInfo();
    } else if (titan.analytics_data.controller_name === "orders") {
      this._purchase();
    }
  }

  _viewItem() {
    window.dataLayer.push({
      'items': this._transformProductsToGtmItems(),
      'event': 'view_item',
      'variant_ids': this._buildArray('variant_id'),
      'sku': titan.analytics_data.products[0].sku,
    });
  }

  _viewCategory() {
    window.dataLayer.push({
      'slug': titan.analytics_data.resource_id,
      'event': 'category_view',
    });
  }

  _addToCart() {
    window.dataLayer.push({
      'items': this._transformProductsToGtmItems(),
      'event': 'add_to_cart',
      'variant_ids': this._buildArray('variant_id'),
      'sku': titan.analytics_data.products[0].sku,
    });
  }

  _beginCheckout() {
    window.dataLayer.push({
      'items': this._transformProductsToGtmItems(),
      'event': 'begin_checkout',
      'currency': 'USD',
      'value': titan.analytics_data.checkout.sub_total,
      'variant_ids': this._buildArray('variant_id'),
      'variant_names': this._buildArray('variant_name'),
    });
  }

  _addPaymentInfo() {
    window.dataLayer.push({
      'items': this._transformProductsToGtmItems(),
      'event': 'add_payment_info',
      'currency': 'USD',
      'value': titan.analytics_data.checkout.sub_total,
    });
  }

  _addShippingInfo() {
    window.dataLayer.push({
      'items': this._transformProductsToGtmItems(),
      'event': 'add_shipping_info',
      'currency': 'USD',
      'value': titan.analytics_data.checkout.sub_total,
    });
  }

  _purchase() {
    window.dataLayer.push({
      'items': this._transformProductsToGtmItems(),
      'variant_ids': this._buildArray('variant_id'),
      'event': 'purchase',
      'currency': 'USD',
      'value': titan.analytics_data.checkout.sub_total,
      'coupon': titan.analytics_data.checkout.coupon,
      'shipping': titan.analytics_data.checkout.shipping,
      'tax': titan.analytics_data.checkout.tax,
      'transaction_id': titan.analytics_data.checkout.token,
      'email_sha256': titan.analytics_data.checkout.email,
      'first_name_sha256': titan.analytics_data.checkout.first_name,
      'last_name_sha256': titan.analytics_data.checkout.last_name,
      'city': titan.analytics_data.checkout.city,
      'state': titan.analytics_data.checkout.state,
      'zip': titan.analytics_data.checkout.zip,
      'country': titan.analytics_data.checkout.country,
    });
  }

  _transformProductsToGtmItems() {
    if (titan.analytics_data.products.length === 0) {
      return [];
    }

    let products = titan.analytics_data.products;

    function gtmItems(product) {
      return Object.entries(this).reduce((newItem, [key, newKey]) => {
        newItem[newKey] = product[key];
        return newItem;
      }, {});
    }
    const gtmTransform = {
      category: 'item_category',
      id: 'item_id',
      name: 'item_name',
      price: 'price',
      variant_name: 'item_variant',
      discount: 'discount',
      coupon: 'coupon',
      quantity: 'quantity',
    };
    return products.map(gtmItems, gtmTransform);
  }

  _buildArray(atrribute) {
    return titan.analytics_data.products.map(function(item) {
      return item[atrribute];
    });
  }
}
/* eslint-enable no-undef */
